export default [
  {
    prop: 'id',
    label: 'STT',
    minWidth: 80,
    type: 'id'
  },
  {
    prop: 'name',
    label: 'Tiêu đề',
    minWidth: 250,
    type: 'text'
  },
  {
    prop: 'title_seo',
    label: 'Tiêu đề SEO',
    minWidth: 250,
    type: 'text'
  }
]
